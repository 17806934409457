@import url('https://fonts.googleapis.com/css2?family=Inter&family=Martel:wght@700;800&family=Montserrat:wght@300;400;500;700&family=Open+Sans:wght@700&family=Poppins:ital,wght@0,400;0,500;0,700;1,400&family=Roboto:wght@100;300;400;500&display=swap');

.smooth-dnd-disable-touch-action *{
    touch-action: auto !important;
}

body {
    background: #CB2525;
    font-family: 'Poppins', sans-serif;
}
.bold {
    font-weight: bold;
    font-style: normal;
}
/* REGISTER */
.wrapper-login {
    background-color: #fff;
    height: 100vh;
    width: 100%;
    padding: 20px 0 100px 0;
}
.login-img{
    /* animation: Img-logo-spin infinite 1s linear; */
    animation: myAnim 5s infinite 0s ease-in-out;
}
@keyframes myAnim {
	0% {
		transform:scale(1);
	}
    50%{
        transform:scale(1.1);
        
    }
    100% {
		transform:scale(1);
	}	
}
.header-login{
    text-align: center;
    margin-bottom: 50px;
    color: #CB2525;
}
.header-login h1{
    font-weight: 700;
}
.title-home {
    text-align: center;
    margin-top: 50px;
    color: #fff;
    font-weight: 700;
}
.form {
    background-color: rgb(202, 37, 37);
    border-radius: 40px;
    /* color: #fff; */
    padding: 50px;
    width: 500px;
    margin: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.text-label{
    color: #fff;
}
.text-invalid{
    color: #ffffff;
    font-size: 0.8em;
}
.btn-regis{
    background-color: #fff;
    color: #CB2525;
    height: 50px;
    width: 150px;
    font-weight: 400;
}

select:invalid {
    color: #777b7e;
  }
/*---------*/
/* style={{position: 'absolute', margin: '0', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: '10px'}} */
.header {
    /* background: #000; */
    margin: 120px auto 50px;
    text-align: center;
    color: #fff;
}

.header h1 {
    font-size: 3em;
    font-weight: 700;
}

.card-question {
    background: #fff;
    border-radius: 35px;
    padding: 60px;
    margin-bottom: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* width: 100%; */
}
.structure-part{
    margin-bottom: 30px;
}
.structure-part p {
    margin-bottom: 10px;
}
.title-question {
    font-size: 1.5em;
    font-weight: 700;
    color: #CB2525;
    margin-bottom: 3    0px;
}
.question-direction {
    font-style: italic;
}
.question-list{
    font-weight: 500;
}
.answer-question {
    border-radius: 100px;
    width: 190px;
    height: 40px;
    border: 1px solid #000;
    color: #000;
    padding: 0 20px 0;
}
.box-question-arrange{
    border: 1px solid #000;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
}
.question-listening{
    margin-bottom: 30px;
}
.audio-listening{
    width: 300px;
}
.listening-answer-container{
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.listening-answer-container .number {
    margin-right: 10px;
}

.btn-submit-test, .btn-nav-test {
    background-color: #CB2525;
    color: #fff;
    height: 50px;
    width: 150px;
    border-radius: 100px;
    font-weight: 700;
    width: 200px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.btn-wrapp {
    text-align: center;
}
.btn-submit-test:hover{
    background-color: #fff;
    color: #CB2525;
    border: 1px solid #fff;
}
.btn-submit-test.reverse{
    background-color: #fff;
    color: #CB2525;
    border: 1px solid #fff;
}
.btn-nav-test.showing{
    background-color: #fff;
    color: #CB2525;
    border: 1px solid #fff;
}

.btn-wrapper {
    position: fixed;
    bottom: 0;
    background: #ffffff;
    width: 100% ;
    height: 90px;
    justify-content: center;
    align-items: center;
    border-radius: 35px 35px 0 0;
    border: 1px solid #CB2525;
}

.btn-navigation, .btn-submit {
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-navigation{
    gap: 5px    ; 
}

@media (width < 769px){
    .wrapper-login{
        height: 100%;
        background-color: #CB2525;
        padding-top: 40px;
    } 
    .header-login{
        color: #fff;
        margin-bottom: 40px;
    }
    .login-img{
        display: none;
    }
    .form{
        width: 95%;
        padding: 30px;
        background-color: #fff;
        color: #000;
        font-weight: 400;
    }
    .text-label{
        color: #000;
    }
    .text-invalid{
        color: #CB2525;
        font-size: 0.8em;
    }
    .btn-regis{
        background-color: #CB2525;
        color: #fff;
        height: 50px;
        width: 150px;
        font-weight: 400;
    }
    .header {
        margin: 50px 0 25px;
    }
    .header h1 {
        font-size: 2em;
        font-weight: 700;
    }
    .answer-question {
        width: 170px;
        margin: auto;
    }
    .card-question {
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 150px;
    }
    .question-list{
        margin-bottom: 40px;
    }
    .audio-listening{
        width: 100%;
    }
    .btn-wrapper{
        height: 130px;
        padding: 10px;
    }
    .btn-navigation{
        gap: 5px;
    }
    .btn-nav-test {
        width: 140px;
        height: 40px;
        font-size: 0.8em;
    }
    .btn-submit-test{
        width: 150px;
        height: 40px;
        font-size: 0.8em;
    }
}

@media (width > 767px){
    .wrapper-login{
        padding-top: 50px;
    }
    .login-img{
        display: none;
    }
    .form{
        width: 500px;
        padding: 50px;
    }
    .card-question {
        border-radius: 15px;
        padding: 40px;
    }
    .audio-listening{
        width: 300px;
    }
}

@media (width > 1023px){
    .wrapper-login{
        height: 100%;
        padding-top: 30px;
    }
    .login-img{
        display: block;
        width: 500px;
        margin: auto;
    }
    .form{
        width: 450px;
    }
    .answer-question{
        width: 130px;
    }
}
@media (width > 1023px){
    .answer-question{
        width: 190px;
    }
}
